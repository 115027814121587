<template>
  <f7-page class="category-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <ProductNavigationComponent title="" type="back" searchname="searchcategorylist" @searched="search" />
    </template>

    <section class="category-list-container">
      <div class="container">
        <f7-row>
          <ul class="category-lists">
            <li :class="{ active: !currentCategoryCode }" @click="clearCategory()">
              <a href="">{{ $t.getTranslation('LBL_ALL') }}</a>
            </li>
            <template v-for="category in categoryList" :key="'ct_' + category.CategoryCode">
              <li :class="{ active: currentCategoryCode == category.CategoryCode }" @click="changeCategory(category)">
                <f7-link href="">{{ category.CategoryName }}</f7-link>
              </li>
            </template>
          </ul>

          <div class="product-lists">
            <div class="options-container">
              <div class="actions">
                <f7-link @click="showFilterPopUp" style="margin-right: 10px">{{ $t.getTranslation('LBL_FILTER') }} <font-awesome-icon :icon="['far', 'filter']" fixed-width /> </f7-link>
                <f7-link @click="showSort"
                  >{{ $t.getTranslation('LBL_SORT') }}
                  <font-awesome-icon :icon="['far', 'arrow-down']" fixed-width />
                </f7-link>
              </div>
            </div>

            <template v-for="product in productList" :key="'pk_' + product.ProductKey">
              <ProductListCardComponent :data="product" :isShop="true" :category="true" />
            </template>
          </div>
        </f7-row>
      </div>
    </section>

    <ProductFilterPopUpComponent :open="openFilterPopup" @closed="onCloseFilterPopup" @selected="onFilterSelected" @cleared="onFilterCleared" />
    <ProductListSortComponent :open="openSortPopup" @closed="onCloseSortPopup" @selected="onSortSelected" @cleared="onSortCleared" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, inject, defineAsyncComponent, computed } from 'vue'

import { useStore } from '@/store'
import { get } from '@/utils/axios'
import { helpers } from '@/utils/helpers.js'
import { configs, extendedConfigs } from '@/utils/configs.js'
// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";
// import ProductNavigationComponent from "@/components/navigations/ProductNavigationComponent.vue";
// import ProductFilterPopUpComponent from "@/components/ProductFilterPopUpComponent.vue";
// import ProductListSortComponent from "@/components/ProductListSortComponent.vue";

const ProductListCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list-card" */ /* webpackMode: "lazy" */ '@/components/cards/ProductListCardComponent.vue'))
const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/ProductNavigationComponent.vue'))
const ProductFilterPopUpComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-fliter-popup" */ /* webpackMode: "lazy" */ '@/components/ProductFilterPopUpComponent.vue'))
const ProductListSortComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list-sort" */ /* webpackMode: "lazy" */ '@/components/ProductListSortComponent.vue'))

export default defineComponent({
  name: 'CategoryListPage',
  components: {
    ProductListCardComponent,
    ProductNavigationComponent,
    ProductFilterPopUpComponent,
    ProductListSortComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore()

    const languageCode = computed(() => store.getters['translation/getLanguage'])

    const $t = inject('$translation')
    const categoryList = ref([])
    const selectedFilterType = ref('')
    const selectedFilterValue = ref('')
    const currentCategoryCode = ref('')
    const supplierCode = ref('')
    const searchValue = ref('')
    const openSortPopup = ref(false)
    const sortfield = ref(null)
    const sortvalue = ref(null)

    const onCloseSortPopup = () => {
      openSortPopup.value = false
    }

    const showSortPopUp = () => {
      openSortPopup.value = true
    }

    const onSortSelected = (data) => {
      onCloseSortPopup()
      sortfield.value = data.SortingField
      sortvalue.value = data.SortingValue
      sortProducts(data.SortingField, data.SortingValue, null)
      return
    }

    const onSortCleared = (data) => {}

    const showSort = () => {
      showSortPopUp()
    }

    const getCategoryList = async () => {
      let ret = await get('/category/list', { LanguageCode: $t.getLanguage(), page: 1, size: 1000 })
      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter]
          categoryList.value.push(item)
        }
      }

      // props.f7router.updateCurrentUrl("/shop/category/list/");
    }

    let page = 1
    let size = 30
    let lastPage = 1
    let isFetching = false
    let isSearch = false
    let sort = ref('DESC')
    let afterSearch = ref('')

    const productList = ref([])
    const showPreloader = ref(false)

    const sortProducts = async (sortfield, sortvalue) => {
      productList.value = []
      page = 1
      await getProductList({ SortingField: sortfield, SortingValue: sortvalue })
    }

    const getProductList = async (sortparams) => {
      if (isFetching) return
      isFetching = true

      let payload = {
        mode: props.mode,
        page: page,
        size: size,
        CategoryValue: currentCategoryCode.value,
        LanguageCode: $t.getLanguage(),
        SupplierCode: supplierCode.value || '',
        SearchValue: searchValue.value || '',
        afterSearch: page > 1 ? afterSearch.value : ''
      }

      if (!extendedConfigs?.isElastic) {
        payload['SearchField'] = 'MULTIPLE'
        payload['SearchType'] = 'LIKE'
        payload['FilterType'] = selectedFilterType.value || ''
        payload['FilterValue'] = selectedFilterValue.value
      }

      if (extendedConfigs?.isElastic) {
        for (const item of selectedFilterValue.value) {
          payload[item.FilterType] = item.FilterValue
        }
      }

      if (!helpers.isBlank(sortfield.value) && !helpers.isBlank(sortvalue.value)) {
        payload.SortingField = sortfield.value
        payload.SortingValue = sortvalue.value
        payload.IsSort = 1
      }

      if (payload?.CategoryValue == 'CROSS_BORDER') {
        payload.CategoryValue = ''
        payload.WareHouseType = 'CROSS_BORDER'
      }

      if (payload?.CategoryValue == 'BONDED') {
        payload.CategoryValue = ''
        payload.WareHouseType = 'BONDED'
      }

      let url = extendedConfigs?.isElastic ? `/mobile/product/elastic/list` : `mobile/product/public/list`

      let ret = await get(url, payload)

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (const [counter, item] of ret.data.entries()) {
          if (extendedConfigs?.isElastic) {
            var productAttribute = item.AttributeList.filter(function(attr) {
              if (attr.LanguageCode == languageCode.value) {
                return attr
              }
            })
            let pickAttr = _.pick(productAttribute[0], ['Name', 'Description'])
            item = { ...item, ...pickAttr }
          }
          productList.value.push(item)
        }

        afterSearch.value = ret.afterSearch ? ret.afterSearch : ''
        isFetching = false
        lastPage = ret?.lastPage
        page++
      }
    }

    const loadMore = () => {
      return lastPage >= page ? getProductList() : false
    }

    const search = (value) => {
      searchValue.value = value
      page = 1
      productList.value = []
      lastPage = 1
      if (isSearch) {
        return true
      }
      isSearch = true
      getProductList()
      isSearch = false
    }

    onMounted(async () => {
      if (props?.f7route?.query?.code) {
        currentCategoryCode.value = props?.f7route?.query?.code
      }

      if (props?.f7route?.query?.SupplierCode) {
        supplierCode.value = props?.f7route?.query?.SupplierCode
      }

      getCategoryList()
      getProductList()
    })

    const changeCategory = async (category) => {
      currentCategoryCode.value = category.CategoryCode

      page = 1
      productList.value = []
      await getProductList()
    }

    const clearCategory = async () => {
      selectedFilterType.value = ''
      selectedFilterValue.value = ''
      currentCategoryCode.value = ''

      page = 1
      productList.value = []
      await getProductList()
    }

    const openFilterPopup = ref(false)

    const onCloseFilterPopup = () => {
      openFilterPopup.value = false
    }

    const showFilterPopUp = () => {
      openFilterPopup.value = true
    }

    const onFilterSelected = (data) => {
      onCloseFilterPopup()

      if (data?.FilterType && data?.FilterValue) {
        selectedFilterType.value = data.FilterType
        selectedFilterValue.value = data.FilterValue

        productList.value = []
        page = 1

        getProductList()
      }
    }

    const onFilterCleared = (data) => {
      currentCategoryCode.value = ''
      selectedFilterType.value = ''
      selectedFilterValue.value = ''

      productList.value = []
      page = 1

      getProductList()
    }

    const onViewProduct = (ProductKey) => {
      props.f7router.navigate({ name: 'productViewPage', params: { code: ProductKey } })
    }

    return {
      categoryList,
      productList,
      loadMore,
      showPreloader,
      onViewProduct,
      currentCategoryCode,
      changeCategory,
      clearCategory,
      openFilterPopup,
      onCloseFilterPopup,
      showFilterPopUp,
      onFilterSelected,
      onFilterCleared,
      search,
      sortProducts,
      sort,
      onCloseSortPopup,
      showSortPopUp,
      onSortSelected,
      onSortCleared,
      openSortPopup,
      showSort
    }
  }
})
</script>

<style scoped>
.product {
  cursor: pointer;
}
</style>
